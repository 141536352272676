import React from "react";
import { submit } from "api/gSheets";

export function useLocal(
  category: string,
  onSave?: (line: string) => void
): [
  string[],
  (line: string) => void,
  (line: string) => void,
  (index: number) => void
] {
  const savedRaw = localStorage.getItem(category);

  const [saved, setSaved] = React.useState<string[]>(
    savedRaw ? JSON.parse(savedRaw) : []
  );

  const handleAdd = (line: string) => {
    const newSaved = [...saved];
    newSaved.push(line);
    setSaved(newSaved);
    localStorage.setItem(category, JSON.stringify(newSaved));
    onSave?.(line);
  };

  const handleRemove = (line: string) => {
    const newSaved = [...saved];
    const index = newSaved.indexOf(line);
    newSaved.splice(index, 1);
    setSaved(newSaved);
    localStorage.setItem(category, JSON.stringify(newSaved));
  };

  const handleRemoveByIndex = (index: number) => {
    const newSaved = [...saved];
    newSaved.splice(index, 1);
    setSaved(newSaved);
    localStorage.setItem(category, JSON.stringify(newSaved));
  };

  return [saved, handleAdd, handleRemove, handleRemoveByIndex];
}

export function useSaved(category: string) {
  const handleSave = (line: string) => {
    submit({
      category: `liked`,
      result: JSON.stringify(line),
      type: category,
    });
  };

  return useLocal(category, handleSave);
}

export function useDisliked(category: string) {
  const handleDislike = (line: string) => {
    submit({
      category: `disliked`,
      result: JSON.stringify(line),
      type: category,
    });
  };

  return useLocal(`${category}-disliked`, handleDislike);
}
