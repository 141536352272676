import {
  IconButton,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

interface ResultRowProps {
  line: string;
  resSize: number;
  savedHooks: [
    string[],
    (line: string) => void,
    (line: string) => void,
    (index: number) => void
  ];
  dislikedHooks: [
    string[],
    (line: string) => void,
    (line: string) => void,
    (index: number) => void
  ];
}

const ResultRow: React.FC<ResultRowProps> = function ResultRow(props) {
  const {  line, resSize, savedHooks, dislikedHooks } = props;

  const [allSaved, onSave, onDelete] = savedHooks;
  const [allDisliked, onDislike, onUndislike] = dislikedHooks;

  const isSaved = React.useMemo(
    () => allSaved.includes(line),
    [line, allSaved]
  );

  const isDisliked = React.useMemo(
    () => allDisliked.includes(line),
    [line, allDisliked]
  );

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  const lineSplit = line.split("--");
  const lineMain = lineSplit[0];

  return (
    <ListItem disableGutters>
      <Stack
        direction="row"
        spacing={2}
        sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton
          onClick={() => (isDisliked ? onUndislike(line) : onDislike(line))}
          sx={{ height: 40 }}
        >
          {isDisliked ? <ThumbDownIcon /> : <ThumbDownOffAltIcon />}
        </IconButton>
        <Stack sx={{ width: "100%" }}>
          <Typography
            variant={resSize > 5 || mobile ? "h5" : "h4"}
            lineHeight={"40px"}
            sx={{ width: "100%" }}
          >
            {lineMain}
          </Typography>
          {lineSplit.length > 1 && (
            <Typography
              variant={"body1"}
              lineHeight={"40px"}
              sx={{ width: "100%" }}
            >
              {lineSplit[1]}
            </Typography>
          )}
        </Stack>
        <IconButton
          onClick={() => (isSaved ? onDelete(line) : onSave(line))}
          sx={{ height: 40 }}
        >
          {isSaved ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
        </IconButton>
      </Stack>
    </ListItem>
  );
};

export default ResultRow;
