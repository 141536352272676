import { RandArray } from "util/randArray";
import adj from "./raw/ratAdj.json";
import job from "./raw/ratJob.json";
import mAdj from '../npc/raw/npcDesc.json'
import mJob from '../npc/raw/npcJob.json'
import { capitalizeFirstLetter as cap } from "util/casing";


function rat() {
    const rAdj = new RandArray([...adj, ...mAdj]);
    const jobs = [...job, ...mJob].filter(job => !job[0].match(/[A-Z]/))
    const rJob = new RandArray(jobs);

    const jobDesc = rJob.random()
    const jdSplit = jobDesc.split(' ').map(word => { if (word === 'the' || word === 'of' || word === 'at') { return word } else { return cap(word) } })
    const jobDescCapitalized = jdSplit.join(' ')
    return `${cap(rAdj.random())} Rat ${jobDescCapitalized}`;
}

export default rat;
