import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NavMenu } from "components";
import { generators, newGenerators } from "generators";
import { NavButton } from "components/ui/Button";

const Navbar: React.FC = function Navbar() {
  const navigate = useNavigate();

  const generatorsArray = Object.keys(generators);
  const newGeneratorsArray = Object.keys(newGenerators);

  const notMisc = ["seaWolf"];

  const tempExclude = ["knucktats"];

  const generatorItems = {} as { [key: string]: () => void };
  generatorsArray.forEach((key) => {
    if (!notMisc.includes(key) && !tempExclude.includes(key)) {
      generatorItems[key] = () => navigate(key);
    }
  });

  const newGeneratorItems = {} as { [key: string]: () => void };
  newGeneratorsArray.forEach((key) => {
    if (!notMisc.includes(key) && !tempExclude.includes(key)) {
      newGeneratorItems[key] = () => navigate(key);
    }
  });

  const notMiscButtons = notMisc.map((key, i) => (
    <NavButton key={key}>{key}</NavButton>
  ));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        px: 1,
      }}
    >
      <div key="notmisc">{notMiscButtons}</div>
      <div>
        <NavMenu label="New" items={newGeneratorItems} />
        <NavMenu label="Misc" items={generatorItems} />
        <NavButton>Suggest</NavButton>
      </div>
    </Box>
  );
};

export default Navbar;
