import { Dialog, DialogTitle } from "@mui/material";

export interface NoMoreCheeseProps {
  open: boolean;
  onClose: () => void;
}
const NoMoreCheese: React.FC<NoMoreCheeseProps> = function NoMoreCheese(props) {
  const { onClose, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Enough!</DialogTitle>
    </Dialog>
  );
};

export default NoMoreCheese;
