import { GobbieComponents } from "./gobbieGen";
import React from "react";
import { HoverText } from "components/generator/HoverText";
import { useFormContext } from "react-hook-form";
import { Header } from "components/ui/Text";
import { Gender } from "types/genTypes";
import { capitalizeFirstLetter as cap } from "util/casing";

export const GobbieResultRow: React.FC<GobbieComponents> = ({
  gender,
  firstPrefix,
  firstSuffix,
  lastPrefix,
  lastSuffix,
}) => {
  const { watch, setValue } = useFormContext<Partial<GobbieComponents>>();

  const fpState = React.useState(false);
  const fsState = React.useState(false);
  const lpState = React.useState(false);
  const lsState = React.useState(false);
  const [firstPrefixL, firstSuffixL, lastPrefixL, lastSuffixL] = watch([
    "firstPrefix",
    "firstSuffix",
    "lastPrefix",
    "lastSuffix",
  ]);
  const firstPrefixLocked = firstPrefixL === firstPrefix;
  const firstSuffixLocked = firstSuffixL === firstSuffix;
  const lastPrefixLocked = lastPrefixL === lastPrefix;
  const lastSuffixLocked = lastSuffixL === lastSuffix;

  const lockFirstPrefix = () => {
    setValue("firstPrefix", !firstPrefixLocked ? firstPrefix : undefined);
  };
  const lockFirstSuffix = () => {
    setValue("firstSuffix", !firstSuffixLocked ? firstSuffix : undefined);
    setValue("gender", !firstSuffixLocked ? gender : Gender.Either);
  };
  const lockLastPrefix = () => {
    setValue("lastPrefix", !lastPrefixLocked ? lastPrefix : undefined);
  };
  const lockLastSuffix = () => {
    setValue("lastSuffix", !lastSuffixLocked ? lastSuffix : undefined);
  };

  return (
    <Header>
      <HoverText
        state={fpState}
        onClick={lockFirstPrefix}
        color={firstPrefixLocked ? "red" : undefined}
      >
        {cap(firstPrefix)}
      </HoverText>
      <HoverText
        state={fsState}
        onClick={lockFirstSuffix}
        color={firstSuffixLocked ? "red" : undefined}
      >
        {firstSuffix}
      </HoverText>{" "}
      <HoverText
        state={lpState}
        onClick={lockLastPrefix}
        color={lastPrefixLocked ? "red" : undefined}
      >
        {cap(lastPrefix)}
      </HoverText>
      <HoverText
        state={lsState}
        onClick={lockLastSuffix}
        color={lastSuffixLocked ? "red" : undefined}
      >
        {lastSuffix}
      </HoverText>
    </Header>
  );
};

export const GobbieSavedRow: React.FC<GobbieComponents> = ({
  firstPrefix,
  firstSuffix,
  lastPrefix,
  lastSuffix,
}) => {
  return (
    <Header>{`${cap(firstPrefix)}${firstSuffix} ${cap(
      lastPrefix
    )}${lastSuffix}`}</Header>
  );
};
