import { Typography, TypographyProps } from "@mui/material";
import { useResponsive } from "hooks/useResponsive";

export const Header: React.FC<TypographyProps> = (props) => {
  const { mobile } = useResponsive();
  return (
    <Typography
      variant={mobile ? "h5" : "h4"}
      lineHeight={"40px"}
      sx={{ width: "100%" }}
      align="left"
      {...props}
    />
  );
};

export const Subheader: React.FC<TypographyProps> = (props) => {
  return (
    <Typography
      variant={"body1"}
      lineHeight={"40px"}
      sx={{ width: "100%" }}
      align="left"
      {...props}
    />
  );
};
