import React from "react";
import { Button } from "components/ui/Button";
import bargains from "./bargains";
import { Stack } from "@mui/material";

const BargainsPage: React.FC = function Generator() {
  const initialValue = bargains();

  const [result, setResult] = React.useState<string>(initialValue);

  const onSubmit = () => {
    setResult(bargains());
  };

  return (
    <>
      <Stack>
        <img src={result} alt="What a bargain!" />
      </Stack>
      <Button onClick={onSubmit}>reroll</Button>
      <div />
    </>
  );
};

export default BargainsPage;
