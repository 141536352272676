import * as yup from "yup";

export const rerollSchema = yup
  .object({
    rerollAmount: yup
      .number()
      .typeError("no!")
      .min(1, "no!")
      .max(25, "chill bro"),
  })
  .required();
