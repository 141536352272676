import { RandArray } from "util/randArray";
import pref from "./raw/prefix.json";
import suff from "./raw/suffix.json";
import suff_f from "./raw/suffix_f.json";
import { Gender } from "types/genTypes";

export interface KenningComponents {
  gender: Gender;
  prefix: [string, string];
  suffix: [string, string];
  kenning: string;
}

export function kenning(lockedComponents: Partial<KenningComponents> = {}) {
  console.log(lockedComponents);
  let gender = lockedComponents.gender;
  if (gender === undefined || String(gender) === String(Gender.Either)) {
    if (Math.random() > 0.5) {
      gender = Gender.Female;
    } else {
      gender = Gender.Male;
    }
  }
  const female = String(gender) === String(Gender.Female);

  const prefix =
    lockedComponents.prefix ??
    new RandArray(pref as [string, string][]).random();
  const suffix =
    lockedComponents.suffix ??
    (female
      ? new RandArray(suff_f as [string, string][]).random()
      : new RandArray(suff as [string, string][]).random());

  const res = {
    prefix,
    suffix,
    gender: female ? Gender.Female : Gender.Male,
  };

  return res;
}

export const defaultKenning = kenning();

export const defaultKenningArgs = {
  gender: Gender.Either,
  prefix: undefined,
  suffix: undefined,
  kenning: undefined,
};

export const genderDependentVals = ["suffix"];
