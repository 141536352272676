import { RandArray } from "util/randArray";
import m from "./raw/sun_m_fn.json";
import f from "./raw/sun_f_fn.json";
import t from "./raw/sun_tribes.json";
import { Gender } from "types/genTypes";

export interface SeekerComponents {
  gender: Gender;
  tribe: string[];
  firstName: string;
  lastName: string;
}

const mLn = [
  "Tia",
  "Tia",
  "Tia",
  "Tia",
  "Tia",
  "Tia",
  "Tia",
  "Tia",
  "Tia",
  "Nunh",
];

export function seeker(lockedComponents: Partial<SeekerComponents> = {}) {
  let gender = lockedComponents.gender;
  if (gender === undefined || String(gender) === String(Gender.Either)) {
    if (Math.random() > 0.5) {
      gender = Gender.Female;
    } else {
      gender = Gender.Male;
    }
  }
  const female = String(gender) === String(Gender.Female);

  const res = {
    tribe: lockedComponents.tribe ?? new RandArray(t).random(),
    firstName:
      lockedComponents.firstName ?? new RandArray(female ? f : m).random(),
    lastName:
      lockedComponents.lastName ?? new RandArray(female ? m : mLn).random(),
    gender: female ? Gender.Female : Gender.Male,
  };
  return res;
}

export const defaultSeeker = seeker();

export const defaultSeekerArgs = {
  rerollAmount: 1,
  gender: Gender.Either,
  tribe: undefined,
  firstName: undefined,
  lastName: undefined,
};

export const genderDependentVals = ["firstName", "lastName"];
