import { RandArray } from "util/randArray";
import mc from "./m_c.json";
import mf from "./m_f.json";
import ml from "./m_l.json";
import fa from "../generic_a.json";
import fb from '../generic_b.json'
import nd from '../no_double.json'
import { capitalizeFirstLetter as cap } from "util/casing";

function plainsfolk_f() {
    const rA = new RandArray(fa);
    const rB = new RandArray(fb.filter(x => !nd.includes(x)));
    let a = rA.random();
    let b = rB.random();

    while (b === a) {
        b = rA.random();
    }
    if ((a[a.length - 1] === "a" || a[a.length - 1] === 'e' || a[a.length - 1] === 'i' || a[a.length - 1] === 'o' || a[a.length - 1] === 'u') && (b[0] === 'a' || b[0] === 'e' || b[0] === 'i' || b[0] === 'o' || b[0] === 'u')) {
        b = b.slice(1);
    }
    let c = b
    if (b[0] === b[1]) {
        b = b.slice(1);
    }
    const fn = a + b + c
    const ln = a + c
    return `${cap(fn)} ${cap(ln)}`;
}

function plainsfolk_m() {
    const rC = new RandArray(mc);
    const rF = new RandArray(mf);
    const rL = new RandArray(ml);
    let c = rC.random();
    let f = rF.random();
    let l = rL.random();
    while (f === l) {
        l = rL.random();
    }
    if (f[f.length - 1] === c[0]) {
        f = f.slice(0, -1);
    }
    if (l[f.length - 1] === c[0]) {
        l = l.slice(0, -1);
    }
    const fn = f + c
    const ln = l + c
    return `${cap(fn)} ${cap(ln)}`;

}

function plainsfolk() {

    if (Math.random() > 0.5) {
        return plainsfolk_f();
    }
    return plainsfolk_m();
}


export default plainsfolk;
