import { RandArray } from "util/randArray";
import f from '../seawolf_f_fn/swffn.json'
import m from '../seawolf_m_fn/swmfn.json'

function ln() {
  if (Math.random() > 0.15) {
    return new RandArray(m).random()
  } else {
    return new RandArray(f).random()
  }

}

function seaWolfBetter() {
  const rF = new RandArray(f);
  const rM = new RandArray(m);

  let firstName = ["", ""];
  let lastName = ln()
  let genderFlag = 0;

  if (Math.random() < 0.5) {
    genderFlag = 1;
    firstName = rF.random()
    if (lastName[0].charAt(lastName[0].length - 1) === "w") {
      lastName[0] = lastName[0].slice(0, -1);
    }
  } else {
    firstName = rM.random();
    if (lastName[0].charAt(lastName[0].length - 1) === "s") {
      lastName[0] = lastName[0].slice(0, -1);
    }
  }

  return `${firstName[0]} ${lastName[0]}${genderFlag ? "wyn" : "syn"} -- ${firstName[1]}, ${genderFlag ? "daughter" : "son"} of ${lastName[1]}`;
}

export default seaWolfBetter;
