export async function submit(obj: { [key: string]: string }) {
    const body = new FormData()
    for (const key in obj) {
        body.append(key, obj[key])
    }
    const scriptUrl =
        "https://script.google.com/macros/s/AKfycbzdjMDzdfnw0PufAdb1vCMcvjB6HY9TpOrpJCq3jfnxD4ZrdXfljK1yFTZK7D9l4IkpoA/exec";
    const res = await fetch(scriptUrl, {
        method: "POST",
        body,
    })
    return res
}
