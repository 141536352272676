import { Button, Dialog, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { submit } from "api/gSheets";
import { RandArray } from "util/randArray";

function Suggest() {
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [credit, setCredit] = React.useState("");
  const [suggestion, setSuggestion] = React.useState("");
  const [suggestionType, setSuggestionType] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const onSubmit = () => {
    setLoading(true);
    submit({
      category: `suggestion`,
      result: suggestion,
      type: suggestionType,
      credit: credit,
    });

    setLoading(false);
    setSuggestion("");
    setSuggestionType("");
    setDone(true);
  };

  const examples = [
    { suggestion: "Vanidier", suggestionType: "Elezen Surname" },
    {
      suggestion: "Livid",
      suggestionType: "descriptor (hellsguard fn, npc adjective)",
    },
    {
      suggestion: "Kheli'sae Elmaleh",
      suggestionType: "Keeper of the Moon name",
    },
    {
      suggestion: "pls add m!viera name generator",
      suggestionType: "miscellaneous",
    },
  ];

  const dummies = [
    "Mogsy",
    "Antioch",
    "Chuarley Higglett",
    "Jimps Morward",
    "a catte",
  ];

  const reroll = () => {
    const rE = new RandArray(examples).random();
    const rD = new RandArray(dummies).random();
    return { ...rE, credit: rD };
  };

  const [example, setExample] = React.useState(reroll());

  if (done) {
    return (
      <Stack
        sx={{
          height: 350,
          py: 4,
          alignItems: "center",
          justifyContent: "flex-start",
          minWidth: 300,
          maxWidth: 450,
        }}
        spacing={8}
      >
        <Typography variant="h4">Thanks, babey!</Typography>
        <Button
          onClick={() => {
            setDone(false);
          }}
          variant="outlined"
          size="large"
        >
          {"i got another one tho"}
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack
          sx={{
            height: 450,
            py: 4,
            alignItems: "center",
            justifyContent: "flex-start",
            width: 450,
          }}
          spacing={2}
        >
          <TextField
            fullWidth
            value={suggestion}
            label="Suggestion"
            id="suggestion"
            required
            onChange={(e) => setSuggestion(e.target.value)}
          />
          <TextField
            fullWidth
            value={suggestionType}
            label="Suggestion Type"
            id="suggestionType"
            required
            onChange={(e) => setSuggestionType(e.target.value)}
            helperText="smth like Keeper last name or Lalafell phoneme"
          />
          <TextField
            fullWidth
            value={credit}
            label="Credit (optional)"
            id="credit"
            onChange={(e) => setCredit(e.target.value)}
            helperText="ur name here so i know who is to blame for your crimes"
          />
          <div>
            <Button
              type="submit"
              variant="outlined"
              size="large"
              disabled={loading || !suggestion || !suggestionType}
              sx={{ mr: 2 }}
            >
              {"Submit"}
            </Button>
            <Button
              variant="outlined"
              size="large"
              onClick={() => setOpen(true)}
            >
              {"Show Example"}
            </Button>
          </div>
        </Stack>
        <Dialog onClose={() => setOpen(false)} open={open}>
          <Stack
            sx={{
              height: 450,
              py: 4,
              px: 4,
              alignItems: "center",
              justifyContent: "center",
              width: 450,
            }}
            spacing={2}
          >
            <TextField
              fullWidth
              value={example.suggestion}
              required
              label="Suggestion"
            />
            <TextField
              fullWidth
              value={example.suggestionType}
              required
              label="Suggestion Type"
            />
            <TextField
              fullWidth
              value={example.credit}
              label="Credit (optional)"
              id="credit"
              onChange={(e) => setCredit(e.target.value)}
            />
            <div>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                onClick={() => {
                  let rerollRes = reroll();
                  while (rerollRes.suggestion === example.suggestion) {
                    rerollRes = reroll();
                  }
                  setExample(rerollRes);
                }}
                sx={{ mr: 2 }}
              >
                {"Another Example"}
              </Button>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                onClick={() => setOpen(false)}
              >
                {"Close"}
              </Button>
            </div>
          </Stack>
        </Dialog>
      </form>
      <div />
    </>
  );
}

export default Suggest;
