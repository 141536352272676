import { RandArray } from "util/randArray";
import mb from "./m_b.json";
import ga from "../generic_a.json";
import fb from '../generic_b.json'
import nd from '../no_double.json'
import { capitalizeFirstLetter as cap } from "util/casing";

function dunesfolk_f() {
    const rA = new RandArray(ga.filter(x => !nd.includes(x)));
    const rB = new RandArray(fb);
    let a = rA.random();
    let b = rB.random();
    while (b === a) {
        b = rA.random();
    }
    if ((a[a.length - 1] === "a" || a[a.length - 1] === 'e' || a[a.length - 1] === 'i' || a[a.length - 1] === 'o' || a[a.length - 1] === 'u') && (b[0] === 'a' || b[0] === 'e' || b[0] === 'i' || b[0] === 'o' || b[0] === 'u')) {
        b = b.slice(1);
    }
    const fn = a + a + b
    const ln = a + b
    if (Math.random() > 0.95) {
        if (Math.random() > 0.5) {
            return `${cap(fn)} Sil ${cap(ln)}`;
        }
        return `${cap(fn)} Ul ${cap(ln)}`
    }
    return `${cap(fn)} ${cap(ln)}`;
}

function dunesfolk_m() {
    const rA = new RandArray(ga.filter(x => !nd.includes(x)));
    const rB = new RandArray(mb);
    let a = rA.random();
    let b = rB.random();
    let c = rA.random();
    while (a.length === 1) {
        a = rA.random();
    }
    while (c.length === 1 || c === a) {
        c = rA.random();
    }
    const fn = a + a + b
    const ln = c + c + b
    if (Math.random() > 0.99) {
        if (Math.random() > 0.5) {
            return `${cap(fn)} Sil ${cap(ln)}`;
        }
        return `${cap(fn)} Ul ${cap(ln)}`
    }
    return `${cap(fn)} ${cap(ln)}`;
}

function dunesfolk() {

    if (Math.random() > 0.5) {
        return dunesfolk_f();
    }
    return dunesfolk_m();
}


export default dunesfolk;
