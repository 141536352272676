import { Button, Stack, TextField } from "@mui/material";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { submit } from "api/gSheets";
import {
  defaultKenning,
  defaultKenningArgs,
  kenning,
  KenningComponents,
  genderDependentVals,
} from "./kenningGen";
import { parseRes } from "./kenningUtil";
import { KenningResultRow } from "./KenningResRow";
import { GenderRadioGroup } from "components/ui/forms/GenderRadioGroup";
import { useResponsive } from "hooks/useResponsive";

function Kenning() {
  const form = useForm<Partial<KenningComponents>>({
    defaultValues: defaultKenningArgs,
  });

  const { mobile } = useResponsive();

  const [result, setResult] =
    React.useState<Omit<KenningComponents, "kenning">>(defaultKenning);

  const onSubmit = form.handleSubmit((data) => {
    if (data.kenning) {
      submit({
        category: `kenning`,
        name: parseRes(result).name,
        literal: parseRes(result).literal,
        kenning: data.kenning,
      });

      setResult(kenning(data));
      form.resetField("kenning");
    }
  });
  const onGood = form.handleSubmit((data) => {
    submit({
      category: `kenning`,
      name: parseRes(result).name,
      literal: parseRes(result).literal,
      kenning: "?",
    });

    setResult(kenning(data));
    form.resetField("kenning");
  });
  const onBad = form.handleSubmit((data) => {
    submit({
      category: "kenning-rejected",
      name: parseRes(result).name,
      literal: parseRes(result).literal,
    });
    setResult(kenning(data));
    form.resetField("kenning");
  });

  const onReroll = form.handleSubmit((data) => {
    setResult(kenning(data));
    form.resetField("kenning");
  });
  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={onSubmit}>
          <Stack
            sx={{
              py: 4,
              alignItems: "center",
              justifyContent: "center",
            }}
            spacing={2}
          >
            <KenningResultRow {...result} />
            <TextField
              fullWidth
              {...form.register("kenning")}
              label="Kenning"
              id="kenning"
              required
            />
            <GenderRadioGroup genderDependent={genderDependentVals} />
            <div>
              <Button
                type="submit"
                variant="outlined"
                size="large"
                sx={{ mr: 2 }}
              >
                {"Submit"}
              </Button>
              <Button
                onClick={onReroll}
                variant="outlined"
                size="large"
                sx={{ mr: 2 }}
              >
                {"Reroll"}
              </Button>
            </div>
            <Stack
              direction={mobile ? "column" : "row"}
              spacing={2}
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button onClick={onGood} variant="outlined" sx={{ mr: 2 }}>
                {"I Like This But I Can't Think Of One"}
              </Button>
              <Button onClick={onBad} variant="outlined" sx={{ mr: 2 }}>
                {"This Sucks"}
              </Button>
            </Stack>
          </Stack>
        </form>
        <div />
      </FormProvider>
    </>
  );
}

export default Kenning;
