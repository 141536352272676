import { RandArray } from "util/randArray";
import gerunds from "./gerunds_mostly.json";

function loporrit() {


    return new RandArray(gerunds).random();

}

export default loporrit;
