import {
  defaultKeeper,
  defaultKeeperArgs,
  genderDependentVals,
  keeper,
} from "./keeperGen";
import { KeeperResultRow, KeeperSavedRow } from "./KeeperRow";

const keeperGen = {
  defaultValue: defaultKeeper,
  defaultValueArgs: defaultKeeperArgs,
  generator: keeper,
  ResultRow: KeeperResultRow,
  SavedRow: KeeperSavedRow,
  genderDependent: genderDependentVals,
};

export default keeperGen;
