import { Button as MuiButton, ButtonProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useResponsive } from "hooks/useResponsive";

export const Button: React.FC<ButtonProps> = ({ sx, ...props }) => {
  const { mobile } = useResponsive();
  return (
    <MuiButton
      variant="outlined"
      size={mobile ? "medium" : "large"}
      {...props}
      sx={{
        height: mobile ? 30 : 40,
        m: 1,
        px: mobile ? 1.5 : 2,
        ...sx,
      }}
    />
  );
};

export const NavButton: React.FC<ButtonProps & { link?: string }> = ({
  ...props
}) => {
  const navigate = useNavigate();

  const link = props.link
    ? props.link
    : typeof props.children === "string"
    ? props.children
    : "";

  return (
    <Button
      onClick={() => {
        navigate(link);
      }}
      {...props}
    />
  );
};
