import { IconButton, ListItem, Stack } from "@mui/material";
import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";

interface GenericResultRowProps {
  line: string;
  savedHooks: [
    string[],
    (line: string) => void,
    (line: string) => void,
    (index: number) => void
  ];
  dislikedHooks: [
    string[],
    (line: string) => void,
    (line: string) => void,
    (index: number) => void
  ];
  children: JSX.Element;
}

const GenericResultRow: React.FC<GenericResultRowProps> = function ResultRow(
  props
) {
  const { line, children, savedHooks, dislikedHooks } = props;

  const [allSaved, onSave, onDelete] = savedHooks;
  const [allDisliked, onDislike, onUndislike] = dislikedHooks;

  const isSaved = React.useMemo(
    () => allSaved.includes(line),
    [line, allSaved]
  );

  const isDisliked = React.useMemo(
    () => allDisliked.includes(line),
    [line, allDisliked]
  );

  return (
    <ListItem disableGutters>
      <Stack
        direction="row"
        spacing={2}
        sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton
          onClick={() => (isDisliked ? onUndislike(line) : onDislike(line))}
          sx={{ height: 40 }}
        >
          {isDisliked ? <ThumbDownIcon /> : <ThumbDownOffAltIcon />}
        </IconButton>
        <Stack sx={{ width: "100%" }}>{children}</Stack>
        <IconButton
          onClick={() => (isSaved ? onDelete(line) : onSave(line))}
          sx={{ height: 40 }}
        >
          {isSaved ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
        </IconButton>
      </Stack>
    </ListItem>
  );
};

export default GenericResultRow;
