import React from "react";
import { IconButton, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSaved } from "hooks/useLocal";
import { NavButton } from "components/ui/Button";
import { Header, Subheader } from "components/ui/Text";

interface SavedProps<T> {
  category: string;
  SavedRow: React.FC<T>;
}

function Saved<T>({ category, SavedRow }: SavedProps<T>) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saved, handleSave, handleDelete, handleDeleteByIndex] =
    useSaved(category);

  return (
    <>
      <Stack
        spacing={1}
        sx={{
          maxHeight: "100%",
          overflowY: "auto",
          maxWidth: "90%",
          minWidth: "60%",
        }}
        id="results"
      >
        {saved.map((line, i) => {
          try {
            return (
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                key={JSON.stringify(line)}
              >
                <Stack sx={{ width: "100%" }}>
                  <SavedRow {...JSON.parse(line)} key={line} />
                </Stack>
                <IconButton onClick={() => handleDeleteByIndex(i)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          } catch (e) {
            // console.error("Old formatted save object detected... " + line);
            const lineSplit = line.split("--");
            const lineMain = lineSplit[0];
            return (
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
                key={JSON.stringify(line)}
              >
                <Stack sx={{ width: "100%" }}>
                  <Header>{lineMain}</Header>
                  {lineSplit.length > 1 && (
                    <Subheader>{lineSplit[1]}</Subheader>
                  )}
                </Stack>
                <IconButton onClick={() => handleDeleteByIndex(i)}>
                  <DeleteIcon />
                </IconButton>
              </Stack>
            );
          }
        })}
      </Stack>
      <NavButton link={`../${category}`}>back</NavButton>
    </>
  );
}

export default Saved;
