import { RandArray } from "util/randArray";
import fn from "./raw/moon_fn.json";
import ln from "./raw/moon_ln.json";
import { Gender } from "types/genTypes";

export interface KeeperComponents {
  gender: Gender;
  firstName: string;
  lastName: string;
  maleSuffix?: string;
}

const mFnSuffComm = new RandArray(["a", "li", "to"]);
const mFnSuffRare = new RandArray([
  "ra",
  "tan",
  "zi",
  "lo",
  "ya",
  "ir",
  "wo",
  "sae",
]);

export function keeper(lockedComponents: Partial<KeeperComponents> = {}) {
  let gender = lockedComponents.gender;
  if (gender === undefined || String(gender) === String(Gender.Either)) {
    if (Math.random() > 0.5) {
      gender = Gender.Female;
    } else {
      gender = Gender.Male;
    }
  }
  const female = String(gender) === String(Gender.Female);

  const res = {
    firstName: lockedComponents.firstName ?? new RandArray(fn).random(),
    maleSuffix: !female
      ? Math.random() > 0.75
        ? new RandArray(mFnSuffRare).random()
        : new RandArray(mFnSuffComm).random()
      : undefined,
    lastName: lockedComponents.lastName ?? new RandArray(ln).random(),
    gender: female ? Gender.Female : Gender.Male,
  };
  return res;
}

export const defaultKeeper = keeper();

export const defaultKeeperArgs = {
  rerollAmount: 1,
  gender: Gender.Either,
  maleSuffix: undefined,
  firstName: undefined,
  lastName: undefined,
};

export const genderDependentVals = ["maleSuffix"];
