import { Typography } from "@mui/material";
import { RatButton } from "misc/fun";
import { useLocation } from "react-router-dom";

const Fallback: React.FC = function Fallback() {
  const location = useLocation();
  if (location.pathname.includes("%F0%9F%90%80")) {
    return (
      <>
        <Typography variant="h1">
          {decodeURI(location.pathname.slice(1))}
        </Typography>
        <RatButton />
      </>
    );
  }
  return (
    <>
      <Typography variant="h1">404</Typography>
      <RatButton />
    </>
  );
};

export default Fallback;
