export function output(input: string): string[] {
  let lines = input.split("\n");
  lines = lines.map(trimTimestamp);
  lines = lines.filter((v) => v !== "");
  lines = lines.map(replaceWithEmDash);
  lines.forEach((v) => console.log(v));
  return lines;
}

function trimTimestamp(line: string) {
  return line.replace(/\[\d\d:\d\d\]/g, "");
}

function replaceWithEmDash(line: string) {
  let res = line.replace(/--/g, "—");
  res = res.replace(/ - /g, " — ");
  res = res.replace(/(\w)—(\w)/g, "$1 — $2");
  return res;
}
