import { RandArray } from "util/randArray";
import fnM from './raw/hiFnM.json'
import fnF from './raw/hiFnF.json'
import ln from './raw/hiLn.json'
import epA from './raw/hiEpA.json'
import epB from './raw/hiEpB.json'
import tiA from './raw/hiTiA.json'
import tiB from './raw/hiTiB.json'

function highlander() {
    const rFn = new RandArray([...fnM, ...fnF]);

    if (Math.random() < 0.5) {
        if (Math.random() < 0.5) {
            const rEpA = new RandArray(epA);
            const rEpB = new RandArray(epB);
            return `${rFn.random()} ${rEpA.random()}${rEpB.random().toLowerCase()}`;

        } else {
            const rTiA = new RandArray(tiA);
            const rTiB = new RandArray(tiB);
            return `${rFn.random()} of the ${rTiA.random()} ${rTiB.random()}`;
        }
    }

    else {
        const rLn = new RandArray(ln);
        return `${rFn.random()} ${rLn.random()}`
    }

}

export default highlander;
