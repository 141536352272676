import {
  defaultGobbie,
  defaultGobbieArgs,
  genderDependentVals,
  gobbie,
} from "./gobbieGen";
import { GobbieResultRow, GobbieSavedRow } from "./GobbieRow";

const gobbieGen = {
  defaultValue: defaultGobbie,
  defaultValueArgs: defaultGobbieArgs,
  generator: gobbie,
  ResultRow: GobbieResultRow,
  SavedRow: GobbieSavedRow,
  genderDependent: genderDependentVals,
};

export { gobbieGen as gobbie };
