import {
  defaultHellsguard,
  defaultHellsguardArgs,
  hellsguard,
} from "./hellsguardGen";
import { HellsguardResultRow, HellsguardSavedRow } from "./HellsguardRow";

const hellsguardGen = {
  defaultValue: defaultHellsguard,
  defaultValueArgs: defaultHellsguardArgs,
  generator: hellsguard,
  ResultRow: HellsguardResultRow,
  SavedRow: HellsguardSavedRow,
};

export { hellsguardGen as hellsguard };
