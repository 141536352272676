import { RandArray } from "util/randArray";
import desc from "./mogDesc.json";
import end from "./mogEnd.json";
import kupA from "./kupA.json";
import kupB from "./kupB.json";
import pukA from "./pukA.json";
import pukB from "./pukB.json";

function genericMoogle() {
    const rDesc = new RandArray(desc);
    return `${rDesc.random()} moogle`;
}

function kup() {
    const rKupA = new RandArray(kupA);
    const rKupB = new RandArray(kupB);
    return `${rKupA.random()} ${rKupB.random()}`;
}

function puk() {
    const rPukA = new RandArray(pukA);
    const rPukB = new RandArray(pukB);
    return `${rPukA.random()} ${rPukB.random()}`;
}

function mog() {
    const rEnd = new RandArray(end);
    return `Mog${rEnd.random()}`;
}

function moogle() {
    const rMoogleA = new RandArray([genericMoogle, kup, puk, mog]);

    const rmoogleFn = rMoogleA.random()
    return rmoogleFn();
}

export default moogle;
