import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { ResultsList, Scrollbox, SpreadRow } from "components/ui/Containers";
import {
  DeepPartial,
  FormProvider,
  UnpackNestedValue,
  useForm,
} from "react-hook-form";
import scroll from "util/scrollToResultsTop";
import { GenderRadioGroup } from "components/ui/forms/GenderRadioGroup";
import { rerollSchema } from "./rerollSchema";
import { RerollCombo } from "components/ui/forms/RerollCombo";
import { genOneToMany } from "util/genOneToMany";
import GenericResultRow from "./GenericResultRow";
import { NavButton } from "components/ui/Button";
import { useDisliked, useSaved } from "hooks/useLocal";
import { AlliterativeCheckbox } from "components/ui/forms/AlliterativeCheckbox";

interface GeneratorProps<T> {
  defaultValue: T;
  defaultValueArgs: UnpackNestedValue<
    DeepPartial<Partial<T> & { rerollAmount: number }>
  >;
  genderDependent?: string[];
  generator(data: UnpackNestedValue<Partial<T>>): T;
  ResultRow: React.FC<T>;
  category: string;
}

function Generator<T>(props: GeneratorProps<T>) {
  const {
    defaultValue,
    defaultValueArgs,
    generator,
    ResultRow,
    category,
    genderDependent,
  } = props;
  const form = useForm<Partial<T> & { rerollAmount: number }>({
    resolver: yupResolver(rerollSchema),
    defaultValues: defaultValueArgs,
  });

  const generators = genOneToMany(generator);

  const onSubmit = form.handleSubmit((data) => {
    setResult(generators(data));
    scroll();
  });

  const [result, setResult] = React.useState<T[]>([defaultValue]);
  const savedHooks = useSaved(category);
  const dislikedHooks = useDisliked(category);
  const alliterativeOption =
    Object.keys(defaultValueArgs).includes("alliterative");

  return (
    <FormProvider {...form}>
      <Scrollbox>
        <ResultsList>
          {result.map((res) => {
            return (
              <GenericResultRow
                line={JSON.stringify(res)}
                key={JSON.stringify(res)}
                savedHooks={savedHooks}
                dislikedHooks={dislikedHooks}
              >
                <ResultRow {...res} key={JSON.stringify(res)} />
              </GenericResultRow>
            );
          })}
        </ResultsList>
      </Scrollbox>
      <Stack alignItems={"center"} sx={{ width: "100%", pt: 1 }}>
        {genderDependent && (
          <GenderRadioGroup genderDependent={genderDependent} />
        )}
        {alliterativeOption && <AlliterativeCheckbox />}
        <SpreadRow>
          <RerollCombo onSubmit={onSubmit} />
          <NavButton>saved</NavButton>
        </SpreadRow>
      </Stack>
    </FormProvider>
  );
}

export default Generator;
