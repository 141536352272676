import React from "react";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import NoMoreCheese from "./NoMoreCheese";

const RatButton: React.FC = function RatButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <>
      <IconButton
        sx={{ alignSelf: "flex-end" }}
        onClick={() => {
          if (
            location.pathname.includes("/%F0%9F%90%80") ||
            location.pathname.includes("/%F0%9F%A7%80")
          ) {
            if (location.pathname.length > (mobile ? 144 : 192)) {
              setOpen(true);
            } else {
              navigate("🧀" + location.pathname.slice(1));
            }
          } else {
            navigate("🐀");
          }
        }}
        size="large"
      >
        🐀
      </IconButton>
      <NoMoreCheese
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default RatButton;
