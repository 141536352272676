import { RandArray } from "util/randArray";
import metal from "./raw/metal.json";
import bird from "./raw/bird.json";

function metalBird() {
  const rMetal = new RandArray(metal);
  const rBird = new RandArray(bird);

  return `${rMetal.random()} ${rBird.random()}`;
}

export default metalBird;
