import {
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
interface SavedProps {
  category: string;
}

const Saved: React.FC<SavedProps> = function Saved({ category }) {
  const savedRaw = localStorage.getItem(category);
  const navigate = useNavigate();

  const [saved, setSaved] = React.useState<string[]>(
    savedRaw ? JSON.parse(savedRaw) : []
  );

  const handleDelete = (index: number) => {
    const newSaved = [...saved];
    newSaved.splice(index, 1);
    setSaved(newSaved);
    localStorage.setItem(category, JSON.stringify(newSaved));
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <>
      <Stack
        spacing={1}
        sx={{
          maxHeight: "100%",
          overflowY: "auto",
          maxWidth: "90%",
          minWidth: "60%",
        }}
        id="results"
      >
        {saved.map((line, i) => {
          const lineSplit = line.split("--");
          const lineMain = lineSplit[0];

          return (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
              key={line}
            >
              <Stack sx={{ width: "100%" }}>
                <Typography
                  variant={saved.length || mobile ? "h5" : "h4"}
                  lineHeight={"40px"}
                >
                  {lineMain}
                </Typography>
                {lineSplit.length > 1 && (
                  <Typography
                    variant={"body1"}
                    lineHeight={"40px"}
                    sx={{ width: "100%" }}
                  >
                    {lineSplit[1]}
                  </Typography>
                )}
              </Stack>
              <IconButton onClick={() => handleDelete(i)}>
                <DeleteIcon />
              </IconButton>
            </Stack>
          );
        })}
      </Stack>
      <Button
        variant="outlined"
        size={mobile ? "medium" : "large"}
        type="submit"
        sx={{
          height: mobile ? 40 : 50,
          m: 2,
        }}
        onClick={() => {
          navigate(`../${category}`);
        }}
      >
        back
      </Button>
    </>
  );
};

export default Saved;
