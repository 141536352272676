
import { RandArray } from "util/randArray";
import adj from "./raw/hoboAdj.json";
import fn from "./raw/hoboFn.json";
import ln from "./raw/hoboLn.json";
import item from "./raw/hoboObject.json";
import title from "./raw/hoboTitle.json";
import mAdj from '../npc/raw/npcDesc.json'
import { capitalizeFirstLetter as cap } from "util/casing";


function hobo() {
    const rAdj = new RandArray(adj);
    const rFn = new RandArray(fn);
    const rLn = new RandArray(ln);
    const rObject = new RandArray(item);
    const rTitle = new RandArray(title);
    const manyAdj = new RandArray([...adj, ...mAdj])

    const classicHobo = () =>
        `${cap(manyAdj.random())} ${rFn.random()} ${rLn.random()}`;
    const withItemHobo = () =>
        `${cap(
            rAdj.random()
        )} ${rFn.random()}, the man with the ${rObject.random()}`;
    const briefHobo = () => `${cap(manyAdj.random())} ${rFn.random()}`;
    const titledHobo = () =>
        `${cap(rAdj.random())} ${rFn.random()}, the ${rTitle.random()}`;
    const otherTitledHobo = () =>
        `${rTitle.random()} ${rFn.random()} ${rLn.random()}`;

    const allHobos = new RandArray([
        classicHobo,
        withItemHobo,
        briefHobo,
        titledHobo,
        otherTitledHobo,
    ]);


    return allHobos.random()()
}

export default hobo;
