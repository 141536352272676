import { RandArray } from "util/randArray";
import adj from "./raw/basicRatDesc.json";
import job from "./raw/basicRatJob.json";
import { capitalizeFirstLetter as cap } from "util/casing";

function rat() {
  const rAdj = new RandArray([...adj]);
  const rJob = new RandArray(job);

  const jobDesc = rJob.random();
  const jdSplit = jobDesc.split(" ").map((word) => {
    if (word === "the" || word === "of" || word === "at") {
      return word;
    } else {
      return cap(word);
    }
  });
  const jobDescCapitalized = jdSplit.join(" ");
  return `${cap(rAdj.random())} Rat ${jobDescCapitalized}`;
}

export default rat;
