import React from "react";
import _ from "lodash";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Button } from "./ui/Button";

interface NavMenuProps {
  label: string;
  items: { [key: string]: () => void };
}

const NavMenu: React.FC<NavMenuProps> = function NavMenu({ label, items }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={
          open ? (
            <ExpandLessIcon sx={{ ml: -0.5, mr: -0.5 }} />
          ) : (
            <ExpandMoreIcon sx={{ ml: -0.5, mr: -0.5 }} />
          )
        }
      >
        {label}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {Object.entries(items).map((item) => {
          const [key, onClickProp] = item;
          const onClick = () => {
            onClickProp();
            handleClose();
          };
          return (
            <MenuItem onClick={onClick} key={key}>
              {_.startCase(key)}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default NavMenu;
