import { Paper, Box, useMediaQuery, useTheme } from "@mui/material";
import { Routes, Navbar } from "nav";

function App() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "action.disabledBackground",
      }}
    >
      <Paper
        sx={{
          height: mobile ? "100%" : "80%",
          width: mobile ? "100%" : 600,
          m: mobile ? 0 : 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          py: 2,
        }}
      >
        <Navbar />
        <Routes />
      </Paper>
    </Box>
  );
}

export default App;
