import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ResultRow from "./OldResultRow";
import { ResultsList, Scrollbox, SpreadRow } from "components/ui/Containers";
import scroll from "util/scrollToResultsTop";
import { RerollCombo } from "components/ui/forms/RerollCombo";
import { NavButton } from "components/ui/Button";
import { rerollSchema } from "components/generator/rerollSchema";
import { genOneToMany } from "util/genOneToMany";
import { useDisliked, useSaved } from "hooks/useLocal";

interface GeneratorProps {
  category: string;
  generate: () => string;
}

const Generator: React.FC<GeneratorProps> = function Generator({
  category,
  generate,
}) {
  const form = useForm({
    resolver: yupResolver(rerollSchema),
    defaultValues: {
      rerollAmount: 1,
    },
  });

  const { handleSubmit } = form;

  const initialValue = generate();

  const [result, setResult] = React.useState<string[]>([initialValue]);
  const savedHooks = useSaved(category);
  const dislikedHooks = useDisliked(category);

  const generators = genOneToMany(generate);

  const onSubmit = handleSubmit((data) => {
    setResult(generators(data));
    scroll();
  });

  return (
    <FormProvider {...form}>
      <Scrollbox>
        <ResultsList>
          {result.map((line) => (
            <ResultRow
              key={line}
              line={line}
              resSize={result.length}
              savedHooks={savedHooks}
              dislikedHooks={dislikedHooks}
            />
          ))}
        </ResultsList>
      </Scrollbox>
      <SpreadRow>
        <RerollCombo onSubmit={onSubmit} />
        <NavButton>saved</NavButton>
      </SpreadRow>
    </FormProvider>
  );
};

export default Generator;
