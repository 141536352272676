import {
  defaultSeeker,
  defaultSeekerArgs,
  genderDependentVals,
  seeker,
} from "./seekerGen";
import { SeekerResultRow, SeekerSavedRow } from "./SeekerRow";

const seekerGen = {
  defaultValue: defaultSeeker,
  defaultValueArgs: defaultSeekerArgs,
  generator: seeker,
  ResultRow: SeekerResultRow,
  SavedRow: SeekerSavedRow,
  genderDependent: genderDependentVals,
};

export default seekerGen;
