import { Stack } from "@mui/material";
import { Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { Button } from "../Button";
import { LittleTextField } from "../fields/LittleTextField";

export const RerollCombo: React.FC<{ onSubmit(): void }> = ({ onSubmit }) => {
  return (
    <Stack direction="row" justifyContent="center">
      <Button onClick={onSubmit}>reroll</Button>

      <Controller
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { error },
        }) => (
          <NumericFormat
            customInput={LittleTextField}
            onBlur={onBlur} // notify when input is touched
            onValueChange={(e) => onChange(e.formattedValue)} // send value to hook form
            value={value}
            inputRef={ref}
            error={!!error}
            allowNegative={false}
            decimalScale={0}
            helperText={error?.message}
          />
        )}
        rules={{ min: 0, max: 25 }}
        name="rerollAmount"
      />
    </Stack>
  );
};
