import { Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { submit } from "api/gSheets";

function SubmitTomestone() {
  const form = useForm({
    defaultValues: {
      suggestion: "",
      credit: "",
    },
  });

  const [done, setDone] = React.useState(false);

  const onSubmit = form.handleSubmit((data) => {
    submit({
      category: `tomestone`,
      result: data.suggestion,
      credit: data.credit,
    });
    setDone(true);
  });

  if (done) {
    return (
      <>
        <Stack
          sx={{
            height: 350,
            py: 4,
            alignItems: "center",
            justifyContent: "flex-start",
            minWidth: 300,
            maxWidth: 450,
          }}
          spacing={8}
        >
          <Typography variant="h4">Thanks, babey!</Typography>
          <Button
            onClick={() => {
              setDone(false);
            }}
            variant="outlined"
            size="large"
          >
            {"i got another one tho"}
          </Button>
        </Stack>
        <div />
      </>
    );
  }
  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack
          sx={{
            py: 4,
            alignItems: "center",
            justifyContent: "flex-start",
            width: 450,
          }}
          spacing={2}
        >
          <TextField
            fullWidth
            {...form.register("suggestion")}
            label="Suggestion"
            id="suggestion"
            required
          />
          <TextField
            fullWidth
            {...form.register("credit")}
            label="Credit"
            id="credit"
            required
          />
          <div>
            <Button
              type="submit"
              variant="outlined"
              size="large"
              sx={{ mr: 2 }}
            >
              {"Submit"}
            </Button>
          </div>
        </Stack>
      </form>
      <div />
    </>
  );
}

export default SubmitTomestone;
