import { RandArray } from "util/randArray";
import choco from "./raw/choco.json";

function chocobo() {
  const rChoco = new RandArray(choco);

  return `${rChoco.random()} ${rChoco.random()}`;
}

export default chocobo;
