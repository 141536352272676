import { RandArray } from "util/randArray";
import fna from "./raw/gob_fna.json";
import fnb from "./raw/gob_fnb.json";
import lna from "./raw/gob_lna.json";
import lnb from "./raw/gob_lnb.json";
import { Gender } from "types/genTypes";
import inflection from "inflection";

export interface GobbieComponents {
  gender: Gender;
  firstPrefix: string;
  firstSuffix: string;
  lastPrefix: string;
  lastSuffix: string;
}

const fnb_f = fnb.filter((x) => x.endsWith("ox") || x.endsWith("onx"));
const fnb_m = fnb.filter((x) => x.endsWith("ix") || x.endsWith("inx"));
const numbers = [
  "two",
  "three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
  "nine",
  "ten",
  "hundred",
  "many",
];

export function gobbie(lockedComponents: Partial<GobbieComponents> = {}) {
  let gender = lockedComponents.gender;
  if (gender === undefined || String(gender) === String(Gender.Either)) {
    if (Math.random() > 0.5) {
      gender = Gender.Female;
    } else {
      gender = Gender.Male;
    }
  }
  const female = String(gender) === String(Gender.Female);

  const lastPrefix = lockedComponents.lastPrefix ?? new RandArray(lna).random();
  let lastSuffix = lockedComponents.lastSuffix ?? new RandArray(lnb).random();
  if (numbers.some((x) => x === lastPrefix)) {
    lastSuffix = inflection.pluralize(lastSuffix);
  }

  const res = {
    firstPrefix: lockedComponents.firstPrefix ?? new RandArray(fna).random(),
    firstSuffix:
      lockedComponents.firstSuffix ??
      (female ? new RandArray(fnb_f).random() : new RandArray(fnb_m).random()),
    lastPrefix,
    lastSuffix,
    gender: female ? Gender.Female : Gender.Male,
  };

  return res;
}

export const defaultGobbie = gobbie();

export const defaultGobbieArgs = {
  rerollAmount: 1,
  gender: Gender.Either,
  firstPrefix: undefined,
  firstSuffix: undefined,
  lastPrefix: undefined,
  lastSuffix: undefined,
};

export const genderDependentVals = ["firstSuffix"];
