import { RandArray } from "util/randArray";
import { capitalizeFirstLetter as cap } from "util/casing";

export function mamoolJa() {
  const rFirstConsonant = new RandArray([
    "g",
    "l",
    "b",
    "m",
    "k",
    "z",
    "h",
    "q",
    "ry",
    "f",
    "v",
    "s",
    "r",
  ]);
  const firstConsonant = rFirstConsonant.random();
  const rVowel = new RandArray(["a", "e", "o", "u"]);
  const firstVowel = firstConsonant === "q" ? "u" : rVowel.random();
  const rVowel2 = new RandArray(["a", "e", "o"]);
  const vowel = rVowel2.random();
  const rSecondConsonant = new RandArray([
    "h",
    "t",
    "g",
    "l",
    "ch",
    "d",
    "p",
    "r",
    "b",
    "f",
    "k",
    "q",
    "gm",
  ]);
  const rEpithet = new RandArray([
    "Lancelord",
    "Wayward",
    "Sagacious",
    "Forbidding",
    "Fleetfingered",
    "Scathing",
    "Redolent",
    "Sanguine",
    "Devout",
    "Hundredfaced",
    "Percipient",
    "Wrothful",
    "Zealous",
    "Jealous",
    "Gyrating",
    "Callow",
    "Ravenous",
    "Rapacious",
    "Tireless",
    "Searing",
    "Supplicating",
    "Resplendent",
    "Ponderous",
    "Odious",
    "Pernicious",
    "Irascible",
    "Furtive",
    "Forlorn",
  ]);
  let pEpithet = "";
  let sEpithet = "";
  if (Math.random() > 0.75) {
    if (Math.random() > 0.25) {
      pEpithet = rEpithet.random() + " ";
    } else {
      sEpithet = " the " + rEpithet.random();
    }
  }
  let heads = 1;
  if (Math.random() > 0.9) {
    heads = 2;
  }
  const name = `${pEpithet}${cap(
    firstConsonant
  )}${firstVowel}${rSecondConsonant.random()}${vowel}${vowel}`;
  if (heads === 1) {
    return `${name}l Ja${sEpithet}`;
  }
  if (Math.random() > 0.5) {
    return `${name}l Ja Ja${sEpithet}`;
  }
  return `${name} Ja Ja${sEpithet}`;
}
