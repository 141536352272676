import React from "react";
import { Button, Dialog, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { output } from "util/formatter";

function Formatter() {
  const form = useForm({
    defaultValues: {
      input: "",
    },
  });

  const [res, setRes] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState(false);

  const onSubmit = form.handleSubmit((data) => {
    setRes(output(data.input));
    setOpen(true);
  });

  const renderRes = res.map((line, i) => {
    const splitLine = line.split(" ");
    const playerName = splitLine.splice(0, 2).join(" ");
    return (
      <Typography key={i}>
        <b>{playerName}</b> {splitLine.join(" ")}
      </Typography>
    );
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack
          sx={{
            py: 4,
            alignItems: "center",
            justifyContent: "flex-start",
            width: 450,
          }}
          spacing={2}
        >
          <TextField
            multiline
            fullWidth
            {...form.register("input")}
            label="Input"
            id="input"
            required
            rows={30}
          />

          <div>
            <Button
              type="submit"
              variant="outlined"
              size="large"
              sx={{ mr: 2 }}
            >
              {"Submit"}
            </Button>
          </div>
        </Stack>
      </form>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <Stack
          sx={{
            py: 4,
            px: 4,
          }}
          spacing={2}
        >
          {renderRes}
        </Stack>
      </Dialog>
    </>
  );
}

export default Formatter;
