import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useFormContext } from "react-hook-form";
import { Gender } from "types/genTypes";

interface GenderRadioGroupProps {
  genderDependent?: string[];
}

export const GenderRadioGroup: React.FC<GenderRadioGroupProps> = ({
  genderDependent = [],
}) => {
  const { register, watch, setValue } = useFormContext();
  const { onChange: onChangeGender, ...genderMethods } = register("gender");
  return (
    <FormControl>
      <FormLabel>Gender</FormLabel>
      <RadioGroup
        {...genderMethods}
        value={watch("gender")}
        onChange={(event) => {
          onChangeGender(event);
          genderDependent.forEach((key) => {
            setValue(key, undefined);
          });
        }}
        defaultValue={Gender.Either}
        sx={{ flexDirection: "row" }}
      >
        <FormControlLabel
          value={Gender.Female}
          control={<Radio {...register("gender")} />}
          label="Female"
        />
        <FormControlLabel
          value={Gender.Male}
          control={<Radio {...register("gender")} />}
          label="Male"
        />
        <FormControlLabel
          value={Gender.Either}
          control={<Radio {...register("gender")} />}
          label="Either"
        />
      </RadioGroup>
    </FormControl>
  );
};
