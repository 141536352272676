import { SeekerComponents } from "./seekerGen";
import { capitalizeFirstLetter as cap } from "util/casing";
import React from "react";
import { HoverText } from "components/generator/HoverText";
import { useFormContext } from "react-hook-form";
import { Header, Subheader } from "components/ui/Text";
import { Gender } from "types/genTypes";

export const SeekerResultRow: React.FC<SeekerComponents> = ({
  gender,
  tribe,
  firstName,
  lastName,
}) => {
  const { watch, setValue } = useFormContext<Partial<SeekerComponents>>();

  const tribeState = React.useState(false);
  const fnState = React.useState(false);
  const lnState = React.useState(false);
  const [tribeL, firstNameL, lastNameL, genderL] = watch([
    "tribe",
    "firstName",
    "lastName",
    "gender",
  ]);
  const tribeLocked = JSON.stringify(tribeL) === JSON.stringify(tribe);
  const fnLocked = firstNameL === firstName;
  const lnLocked = lastNameL === lastName;

  const lockTribe = () => {
    setValue("tribe", !tribeLocked ? tribe : undefined);
  };
  const lockFn = () => {
    setValue("firstName", !fnLocked ? firstName : undefined);
    setValue(
      "gender",
      !fnLocked ? gender : fnLocked && !lnLocked ? Gender.Either : genderL
    );
  };
  const lockLn = () => {
    setValue("lastName", !lnLocked ? lastName : undefined);
    setValue(
      "gender",
      !lnLocked ? gender : !fnLocked && lnLocked ? Gender.Either : genderL
    );
  };

  return (
    <>
      <Header>
        <HoverText
          state={tribeState}
          onClick={lockTribe}
          color={tribeLocked ? "red" : undefined}
        >
          {tribe[0]}
        </HoverText>
        '
        <HoverText
          state={fnState}
          onClick={lockFn}
          color={fnLocked ? "red" : undefined}
        >
          {firstName}
        </HoverText>{" "}
        <HoverText
          state={lnState}
          onClick={lockLn}
          color={lnLocked ? "red" : undefined}
        >
          {cap(lastName)}
        </HoverText>
      </Header>
      <Subheader>
        <HoverText
          state={fnState}
          onClick={lockFn}
          color={fnLocked ? "red" : undefined}
        >
          {cap(firstName)}
        </HoverText>{" "}
        of the{" "}
        <HoverText
          state={tribeState}
          onClick={lockTribe}
          color={tribeLocked ? "red" : undefined}
        >
          {tribe[1]}
        </HoverText>{" "}
        tribe
      </Subheader>
    </>
  );
};

export const SeekerSavedRow: React.FC<SeekerComponents> = ({
  tribe,
  firstName,
  lastName,
}) => {
  return (
    <>
      <Header>{`${tribe[0]}'${firstName} ${cap(lastName)}`}</Header>
      <Subheader>{`${cap(firstName)} of the ${tribe[1]} tribe`}</Subheader>
    </>
  );
};
