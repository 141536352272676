export class RandArray<T> extends Array<T> {

    constructor(val: Array<T> = []) {
        super(...val)
    }

    public random(): T {
        return this[Math.floor(Math.random() * this.length)]
    }

}