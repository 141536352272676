import { RandArray } from "util/randArray";
import a from "./swffn.json";

function seaWolfFFirstName() {
  const rA = new RandArray(a);
  const randRes = rA.random();

  return `${randRes[0]} -- ${randRes[1]}`;
}

export default seaWolfFFirstName;
