import React from "react";
import {
  Button,
  Stack,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useForm } from "react-hook-form";
import _ from "lodash";

// Custom sentenceCase function that handles capitalizing "I" correctly
function sentenceCase(str = "") {
  const lowerStr = str.toLowerCase();
  let result = "";
  let capitalizeNext = true;

  for (let i = 0; i < lowerStr.length; i++) {
    let char = lowerStr.charAt(i);

    if (capitalizeNext && char.trim()) {
      char = char.toUpperCase();
      capitalizeNext = false;
    }

    if (
      char === "." ||
      char === "!" ||
      char === "?" ||
      char === "\n" ||
      char === "\r"
    ) {
      capitalizeNext = true;
    }

    result += char;
  }

  // Replace " i " with " I " to handle "I" correctly
  result = result.replace(/\bi\b/g, "I");

  return result;
}

// Custom alternatingCase function
function alternatingCase(str = "") {
  return str
    .split("")
    .map((char, index) =>
      index % 2 === 1 ? char.toUpperCase() : char.toLowerCase()
    )
    .join("");
}

// Custom inverseCase function
function inverseCase(str = "") {
    return str
    .split("")
    .map((char, index) =>
      index % 2 === 0 ? char.toUpperCase() : char.toLowerCase()
    )
    .join("");
}

const lodashOperations: { [key: string]: (string?: string) => string } = {
  "Sentence case": sentenceCase, // Use custom sentenceCase function
  "kebab-case": _.kebabCase,
  "lower case": _.lowerCase,
  "UPPER CASE": _.upperCase,
  camelCase: _.camelCase,
  snake_case: _.snakeCase,
  "Start Case": _.startCase,
  "aLtErNaTiNg cAsE": alternatingCase, // New custom operation
  "InVeRsE CaSe": inverseCase, // New custom operation
};

function Lodash() {
  const form = useForm({
    defaultValues: {
      input: "",
    },
  });

  const [result, setResult] = React.useState("");
  const [selectedOperation, setSelectedOperation] =
    React.useState("Sentence case");

  const onSubmit = form.handleSubmit((data) => {
    const operation = lodashOperations[selectedOperation];
    const result = operation(data.input);
    setResult(result);
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack
        sx={{
          py: 4,
          alignItems: "center",
          justifyContent: "flex-start",
          width: 450,
        }}
        spacing={2}
      >
        <TextField
          multiline
          fullWidth
          {...form.register("input")}
          label="Input"
          id="input"
          required
          rows={10}
        />

        <FormControl fullWidth>
          <InputLabel id="operation-select-label">Operation</InputLabel>
          <Select
            labelId="operation-select-label"
            id="operation-select"
            value={selectedOperation}
            label="Operation"
            onChange={(e) => setSelectedOperation(e.target.value)}
          >
            {Object.keys(lodashOperations).map((operation) => (
              <MenuItem key={operation} value={operation}>
                {operation}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          multiline
          fullWidth
          label="Output"
          id="output"
          rows={10}
          InputProps={{
            readOnly: true,
          }}
          value={result}
        />

        <div>
          <Button type="submit" variant="outlined" size="large" sx={{ mr: 2 }}>
            {"Submit"}
          </Button>
        </div>
      </Stack>
    </form>
  );
}

export default Lodash;
