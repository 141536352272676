import { RandArray } from "util/randArray";
import a from "./raw/seaWolfA.json";
import bm from "./raw/seaWolfB_M.json";
import bf from "./raw/seaWolfB_F.json";
import { capitalizeFirstLetter as cap } from "util/casing";

function seaWolf() {
  const rA = new RandArray(a);

  const genName = (arr: string[][]) => {
    const rB = new RandArray(arr);
    const mNameParts = [rA.random(), rB.random()];
    const mName = [mNameParts[0][0], mNameParts[1][0]];
    const mDef = [mNameParts[0][1], mNameParts[1][1]];

    let mNameConcat = mName[0] + mName[1];
    if (mName[0].charAt(mName[0].length - 1) === mName[1].charAt(0)) {
      mNameConcat = mName[0] + mName[1].substring(1);
    }
    mNameConcat = cap(mNameConcat);
    const mDefConcat = cap(mDef[0]) + " " + cap(mDef[1]);
    return [mNameConcat, mDefConcat];
  };

  let firstName = ["", ""];
  let lastName = genName(bm);
  let genderFlag = 0;

  if (Math.random() < 0.5) {
    genderFlag = 1;
    firstName = genName(bf);
    lastName[0] = lastName[0] + "wyn";
  } else {
    firstName = genName(bm);
    if (lastName[0].charAt(lastName[0].length - 1) === "s") {
      lastName[0] = lastName[0].slice(0, -1);
    }
    lastName[0] = lastName[0] + "syn";
  }

  return `${firstName[0]} ${lastName[0]}
    -- 
    ${firstName[1]}, ${genderFlag ? "daughter" : "son"} of ${lastName[1]}`;
}

export default seaWolf;
