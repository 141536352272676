import {
  TextFieldProps,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export const LittleTextField: React.FC<TextFieldProps> = ({
  InputProps,
  inputProps,
  FormHelperTextProps,
  ...props
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        sx: {
          height: mobile ? 30 : 40,
          width: 45,
          my: 1,
          mx: 0,
          ...InputProps?.sx,
        },
      }}
      inputProps={{
        ...inputProps,
        maxLength: 2,
        style: { paddingInline: 12 },
      }}
      FormHelperTextProps={{
        ...FormHelperTextProps,
        sx: {
          position: "absolute",
          bottom: -12,
          left: 0,
          p: 0,
          m: 0,
          whiteSpace: "nowrap",
          ...FormHelperTextProps?.sx,
        },
      }}
    />
  );
};
