import { RandArray } from "util/randArray";
import lastNames from "./raw/elLastName.json"

export const soupedUpLastName = () => {
    const rLastName = new RandArray(lastNames);
    let randomLastName = rLastName.random().toLowerCase()
    let de = false // "of"
    let la = false // "the"
    let du = false // "of the"

    // Should this be a fancy name? 33% chance
    if (Math.random() < 0.33) {
        de = true
    }
    if (Math.random() < 0.16) {
        la = true
    }

    // Does the last name natively begin with de  or d'(case insensitive)?
    if (randomLastName.startsWith("de") || randomLastName.startsWith("d'")) {
        de = true
        la = false
        // trim leading De
        randomLastName = randomLastName.substring(2)
    }

    // Does the last name natively begin with la or le or l' (case insensitive)?
    if (randomLastName.startsWith("la") || randomLastName.startsWith("le") || randomLastName.startsWith("l'")) {
        la = true
        // trim leading La
        randomLastName = randomLastName.substring(2)
        randomLastName = randomLastName.trim()
        // does this leave us with two leading consonants?
        if (randomLastName.length > 2 && randomLastName.substring(0, 2).match(/[bcdfghjklmnpqrstvwxyz]/i)) {
            la = false
            let prefix = 'La'
            if (Math.random() < 0.33) {
                prefix = 'Le'

            } else if (Math.random() < 0.25) {
                prefix = 'L\u00e9'
            }
            randomLastName = prefix + randomLastName
        }
    }

    if (randomLastName.startsWith("l\u00e9")) {
        la = false
    }

    // Does the last name natively begin with du (case insensitive)?
    if (randomLastName.startsWith("du")) {
        du = true
        de = false
        la = false
        // trim leading Du
        randomLastName = randomLastName.substring(2)
    }



    // occasionally replace de la with du
    if (de && la) {
        if (Math.random() < 0.25) {
            de = false
            la = false
            du = true
        }
    }

    // is the last name hyphenated?
    if (randomLastName.includes("-")) {
        de = false
        la = false
        du = false
    }

    // add a particle
    let particle = ''
    if (de) {
        if (!la) {
            if (Math.random() < 0.25) {
                particle
                    += "d'"
            } else {
                particle
                    += "de "
            }
        } else {
            particle
                += "de "
        }
    } else if (du) {
        particle
            += "du "
    }
    if (la) {

        if (Math.random() < 0.25) {
            particle
                += "l'"
        } else {
            particle
                += "la "
        }
    }

    if (randomLastName.length < 5) {
        particle = "Dela"
    } else {


        // capitalize first letter
        randomLastName = randomLastName.charAt(0).toUpperCase() + randomLastName.slice(1)

    }
    // de is used for place names usually, like Montrecourt de Riviaux would be Montrecourt of Riviaux
    // la is used for thing names, and similarly de la would be for thing names too or implies land ownership?
    // how to figure out if last name is place or thing?
    // either rescrape with metadata
    // or try using nlp guessing
    // low priority

    // Experimental: If the last name does not end with a vowel, add an "e" to the end
    if (!/[aeiouy]$/i.test(randomLastName)) {
        if (Math.random() < 0.16) {
            randomLastName += "e"
        }
    }

    // clean any leading spaces if needed
    randomLastName = randomLastName.trimStart()


    return `${particle}${randomLastName}`

}
