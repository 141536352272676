import React from "react";
import detectTouch from "util/detectTouch";

interface HoverTextProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onClick?(): void;
  color?: string;
  hoverColor?: string;
}

export const HoverText: React.FC<React.PropsWithChildren<HoverTextProps>> = ({
  children,
  state,
  onClick,
  color,
  hoverColor = "red",
}) => {
  const touch = detectTouch();
  return (
    <span
      onMouseOver={() => state[1](true)}
      onMouseOut={() => state[1](false)}
      onClick={onClick}
      style={{
        color: state[0] && !touch ? hoverColor : color,
        cursor: "pointer",
        textDecoration: touch ? "underline" : undefined,
      }}
    >
      {children}
    </span>
  );
};
