import { UnpackNestedValue } from "react-hook-form";

export function genOneToMany<T>(
  generator: (data: UnpackNestedValue<Partial<T>>) => T
) {
  function generators(
    data: UnpackNestedValue<Partial<T> & { rerollAmount: number }>
  ) {
    const { rerollAmount = 1 } = data;
    const res: T[] = [];
    const resKey: string[] = [];
    for (let step = 0; step < rerollAmount; step++) {
      const rand = generator(data);
      if (!resKey.includes(JSON.stringify(rand))) {
        res.push(rand);
        resKey.push(JSON.stringify(rand));
      }
    }
    return res;
  }

  return generators;
}
