import { RandArray } from "util/randArray";
import knuck from "./raw/knuck.json";
import tat from "./raw/tat.json";


function knucktats() {
    const rKnuck = new RandArray(knuck);
    const rTat = new RandArray(tat);

    return `${rKnuck.random()} ${rTat.random()}`
}

export default knucktats;
