import { RandArray } from "util/randArray";
import a from "./swmfn.json";

function seaWolfMFirstName() {
  const rA = new RandArray(a);
  const randRes = rA.random();

  return `${randRes[0]} -- ${randRes[1]}`;
}

export default seaWolfMFirstName;
