import { FormControlLabel, Checkbox, FormGroup } from "@mui/material";
import { useFormContext } from "react-hook-form";

export const AlliterativeCheckbox: React.FC = () => {
  const { register } = useFormContext();
  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox {...register("alliterative")} defaultChecked />}
        label="Alliterative"
      />
    </FormGroup>
  );
};
