import { elezen } from "./elezen";
import { hobo } from "./hobo";
import { rat } from "./rat";
import { knucktats } from "./knucktats";
import { metalBird } from "./metalbird";
import { npc } from "./npc";
import { loporrit } from "./loporrit";
import { highlander } from "./highlander";
import { hellsguard } from "./hellsguard";
import { seaWolf as seaWolfOld } from "./seawolf";
import { seaWolfBetter as seaWolf } from "./seawolfBetter";
import { seaWolfFFirstName as fRoe } from "./seawolf_f_fn";
import { dergo } from "./dergo";
import { seaWolfMFirstName as mRoe } from "./seawolf_m_fn";
import { moogle } from "./moogle";
import { keeper, seeker } from "./miqote";
import { dunesfolk, plainsfolk } from "./lalafell";
import { gobbie } from "./gobbie";
import { fancyRat } from "./fancyrat";
import { tropes } from "./tropes";
import { chocobo } from "./chocobo";
import { mamoolJa } from "./mamoolja";

export const generators = {
  seaWolf,
  fRoe,
  mRoe,
  dunesfolk,
  plainsfolk,
  dergo,
  loporrit,
  moogle,
  elezen,
  hobo,
  rat,
  knucktats,
  metalBird,
  highlander,
  seaWolfOld,
  fancyRat,
  tropes,
  chocobo,
  mamoolJa,
};

export const newGenerators = {
  npc,
  hellsguard,
  keeper,
  seeker,
  gobbie,
};

export default generators;
