import { RandArray } from "util/randArray";
import tropes from './raw/tropes.json'


function genTrope() {
  const rTropes = new RandArray(tropes);
  let tropeA = rTropes.random()
  let tropeB = rTropes.random()
  while (tropeA === tropeB) {
    tropeB = rTropes.random()
  } 


  return `${tropeA} x ${tropeB}`;
}

export default genTrope;
