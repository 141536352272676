import { NpcComponents } from "./npcGen";
import { capitalizeFirstLetter as cap } from "util/casing";
import React from "react";
import { HoverText } from "components/generator/HoverText";
import { useFormContext } from "react-hook-form";
import { Header } from "components/ui/Text";

export const NpcResultRow: React.FC<NpcComponents> = ({
  firstName,
  lastName,
}) => {
  const { watch, setValue } = useFormContext<Partial<NpcComponents>>();

  const fnState = React.useState(false);
  const lnState = React.useState(false);

  const [firstNameL, lastNameL] = watch(["firstName", "lastName"]);

  const fnLocked = firstNameL === firstName;
  const lnLocked = lastNameL === lastName;

  const lockFn = () => {
    setValue("firstName", !fnLocked ? firstName : undefined);
    setValue("lastName", undefined);
  };
  const lockLn = () => {
    setValue("firstName", undefined);
    setValue("lastName", !lnLocked ? lastName : undefined);
  };

  return (
    <>
      <Header>
        <HoverText
          state={fnState}
          onClick={lockFn}
          color={fnLocked ? "red" : undefined}
        >
          {cap(firstName)}
        </HoverText>{" "}
        <HoverText
          state={lnState}
          onClick={lockLn}
          color={lnLocked ? "red" : undefined}
        >
          {cap(lastName)}
        </HoverText>
      </Header>
    </>
  );
};

export const NpcSavedRow: React.FC<NpcComponents> = ({
  firstName,
  lastName,
}) => {
  return <Header>{`${cap(firstName)} ${cap(lastName)}`}</Header>;
};
