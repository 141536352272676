import { soupedUpLastName } from "./names"
import { RandArray } from "util/randArray";
import titles from "./raw/elTitles.json"
import firstNames from "./raw/elFirstName.json"

function elezen() {
    let titled = false

    if (Math.random() < 0.16) {
        titled = true
    }

    const rTitles = new RandArray(titles)
    const rFirstNames = new RandArray(firstNames)

    return `${titled ? rTitles.random() + " " : ""}${rFirstNames.random()} ${soupedUpLastName()}`
}

export default elezen;
