import {
    defaultNpc,
    defaultNpcArgs,
    npc,
  } from "./npcGen";
  import { NpcResultRow, NpcSavedRow } from "./NpcRow";
  
  const npcGen = {
    defaultValue: defaultNpc,
    defaultValueArgs: defaultNpcArgs,
    generator: npc,
    ResultRow: NpcResultRow,
    SavedRow: NpcSavedRow,
  };
  
  export { npcGen as npc };
  