import { Box, BoxProps, List, ListProps } from "@mui/material";
import { useResponsive } from "hooks/useResponsive";

export const Scrollbox: React.FC<BoxProps> = ({ sx, ...props }) => {
  return (
    <Box
      justifyContent="center"
      {...props}
      sx={{
        maxHeight: "100%",
        overflowY: "auto",
        width: "100%",
        display: "flex",
        ...sx,
      }}
    />
  );
};

export const ResultsList: React.FC<ListProps> = ({
  sx,
  children,
  ...props
}) => {
  const { mobile } = useResponsive();
  return (
    <List
      id="results"
      dense={mobile}
      {...props}
      sx={{
        px: 2,
        ...sx,
      }}
    >
      <div id="results-top" />
      {children}
    </List>
  );
};

export const SpreadRow: React.FC<BoxProps> = ({ sx, ...props }) => {
  return (
    <Box
      justifyContent="space-between"
      {...props}
      sx={{
        m: 1,
        display: "flex",
        flexDirection: "row",
        width: "75%",
        ...sx,
      }}
    />
  );
};
