import { KeeperComponents } from "./keeperGen";
import { capitalizeFirstLetter as cap } from "util/casing";
import React from "react";
import { HoverText } from "components/generator/HoverText";
import { useFormContext } from "react-hook-form";
import { Header } from "components/ui/Text";
import { Gender } from "types/genTypes";

export const KeeperResultRow: React.FC<KeeperComponents> = ({
  gender,
  maleSuffix,
  firstName,
  lastName,
}) => {
  const { watch, setValue } = useFormContext<Partial<KeeperComponents>>();

  const maleSuffixState = React.useState(false);
  const fnState = React.useState(false);
  const lnState = React.useState(false);
  const [maleSuffixL, firstNameL, lastNameL] = watch([
    "maleSuffix",
    "firstName",
    "lastName",
  ]);
  const maleSuffixLocked =
    JSON.stringify(maleSuffixL) === JSON.stringify(maleSuffix);
  const fnLocked = firstNameL === firstName;
  const lnLocked = lastNameL === lastName;

  const lockMaleSuffix = () => {
    setValue("maleSuffix", !maleSuffixLocked ? maleSuffix : undefined);
    setValue("gender", !maleSuffixLocked ? gender : Gender.Either);
  };
  const lockFn = () => {
    setValue("firstName", !fnLocked ? firstName : undefined);
  };
  const lockLn = () => {
    setValue("lastName", !lnLocked ? lastName : undefined);
  };

  return (
    <Header>
      <HoverText
        state={fnState}
        onClick={lockFn}
        color={fnLocked ? "red" : undefined}
      >
        {firstName}
      </HoverText>
      {maleSuffix && (
        <>
          {"'"}
          <HoverText
            state={maleSuffixState}
            onClick={lockMaleSuffix}
            color={maleSuffixLocked ? "red" : undefined}
          >
            {maleSuffix}
          </HoverText>
        </>
      )}{" "}
      <HoverText
        state={lnState}
        onClick={lockLn}
        color={lnLocked ? "red" : undefined}
      >
        {cap(lastName)}
      </HoverText>
    </Header>
  );
};

export const KeeperSavedRow: React.FC<KeeperComponents> = ({
  maleSuffix,
  firstName,
  lastName,
}) => {
  return (
    <Header>{`${firstName}${maleSuffix ? `'${maleSuffix}` : ""} ${cap(
      lastName
    )}`}</Header>
  );
};
