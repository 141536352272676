import { KenningComponents } from "./kenningGen";
import React from "react";
import { HoverText } from "components/generator/HoverText";
import { useFormContext } from "react-hook-form";
import { Header, Subheader } from "components/ui/Text";
import { Gender } from "types/genTypes";
import { capitalizeFirstLetter as cap } from "util/casing";

export const KenningResultRow: React.FC<Omit<KenningComponents,'kenning'>> = ({
  gender,
  prefix,
  suffix,
}) => {
  const { watch, setValue } = useFormContext<Partial<KenningComponents>>();

  const pState = React.useState(false);
  const sState = React.useState(false);
  const [prefixL, suffixL] = watch(["prefix", "suffix"]);
  const prefixLocked = JSON.stringify(prefixL) === JSON.stringify(prefix);
  const suffixLocked = JSON.stringify(suffixL) === JSON.stringify(suffix);

  const lockPrefix = () => {
    setValue("prefix", !prefixLocked ? prefix : undefined);
  };
  const lockSuffix = () => {
    setValue("suffix", !suffixLocked ? suffix : undefined);
    setValue("gender", !suffixLocked ? gender : Gender.Either);
  };

  return (
    <>
      <Header>
        <HoverText
          state={pState}
          onClick={lockPrefix}
          color={prefixLocked ? "red" : undefined}
        >
          {cap(prefix[0])}
        </HoverText>
        <HoverText
          state={sState}
          onClick={lockSuffix}
          color={suffixLocked ? "red" : undefined}
        >
          {suffix[0]}
        </HoverText>
      </Header>
      <Subheader>
        <HoverText
          state={pState}
          onClick={lockPrefix}
          color={prefixLocked ? "red" : undefined}
        >
          {cap(prefix[1])}
        </HoverText>{" "}
        <HoverText
          state={sState}
          onClick={lockSuffix}
          color={suffixLocked ? "red" : undefined}
        >
          {cap(suffix[1])}
        </HoverText>
      </Subheader>
    </>
  );
};
