import { RandArray } from "util/randArray";
import fn from "./raw/hellsguardFn.json";
import ln from "./raw/hellsguardLn.json";
import inflection from "inflection";

const numbers = [
  "Two",
  "Three",
  "Four",
  "Five",
  "Six",
  "Seven",
  "Eight",
  "Nine",
  "Ten",
  "Hundred",
];

export interface HellsguardComponents {
  alliterative: boolean;
  firstName: string;
  lastName: string;
}

function alliterativeHellsguard(
  lockedComponents: Partial<HellsguardComponents> = {}
) {
  let { firstName, lastName } = lockedComponents;
  if (firstName && lastName) {
    return {
      firstName: firstName,
      lastName: lastName,
    };
  }
  if (!firstName && !lastName) {
    if (Math.random() > 0.5) {
      firstName = new RandArray(fn).random();
    } else {
      lastName = new RandArray(ln).random();
    }
  }
  if (firstName) {
    const firstLetter = firstName.charAt(0).toUpperCase();
    lastName = new RandArray([
      ...ln.filter((n) => n.charAt(0).toUpperCase() === firstLetter),
    ]).random();
    if (lastName === undefined) {
      return regularHellsguard({ firstName });
    }
    if (numbers.some((x) => x === firstName)) {
      lastName = inflection.pluralize(lastName);
    }
    return {
      firstName,
      lastName,
    };
  }
  if (lastName) {
    const firstLetter = lastName.charAt(0).toUpperCase();
    firstName = new RandArray([
      ...fn.filter((n) => n.charAt(0).toUpperCase() === firstLetter),
    ]).random();
    if (firstName === undefined) {
      return regularHellsguard({ lastName });
    }
    if (numbers.some((x) => x === firstName)) {
      lastName = inflection.pluralize(lastName);
    }
    return {
      lastName,
      firstName,
    };
  }
}

function regularHellsguard(
  lockedComponents: Partial<HellsguardComponents> = {}
) {
  let firstName = lockedComponents.firstName ?? new RandArray(fn).random();
  let lastName = lockedComponents.lastName ?? new RandArray(ln).random();
  if (numbers.some((x) => x === firstName)) {
    lastName = inflection.pluralize(lastName);
  }
  const res = {
    firstName,
    lastName,
  };
  return res;
}

export function hellsguard(
  lockedComponents: Partial<HellsguardComponents> = {}
) {
  // Minor bias to provide alliterative names
  if (Math.random() > 0.75 || lockedComponents.alliterative) {
    return alliterativeHellsguard(lockedComponents);
  } else {
    return regularHellsguard(lockedComponents);
  }
}

export const defaultHellsguard = hellsguard();

export const defaultHellsguardArgs = {
  rerollAmount: 1,
  alliterative: false,
  firstName: undefined,
  lastName: undefined,
};
