import Bargain01 from "./DevilsBargain-01.png";
import Bargain02 from "./DevilsBargain-02.png";
import Bargain03 from "./DevilsBargain-03.png";
import Bargain04 from "./DevilsBargain-04.png";
import Bargain05 from "./DevilsBargain-05.png";
import Bargain06 from "./DevilsBargain-06.png";
import Bargain07 from "./DevilsBargain-07.png";
import Bargain08 from "./DevilsBargain-08.png";
import Bargain09 from "./DevilsBargain-09.png";
import Bargain10 from "./DevilsBargain-10.png";
import Bargain11 from "./DevilsBargain-11.png";
import Bargain12 from "./DevilsBargain-12.png";
import Bargain13 from "./DevilsBargain-13.png";
import Bargain14 from "./DevilsBargain-14.png";
import Bargain15 from "./DevilsBargain-15.png";
import Bargain16 from "./DevilsBargain-16.png";
import Bargain17 from "./DevilsBargain-17.png";
import Bargain18 from "./DevilsBargain-18.png";
import Bargain19 from "./DevilsBargain-19.png";
import Bargain20 from "./DevilsBargain-20.png";
import Bargain21 from "./DevilsBargain-21.png";
import Bargain22 from "./DevilsBargain-22.png";
import Bargain23 from "./DevilsBargain-23.png";
import Bargain24 from "./DevilsBargain-24.png";
import Bargain25 from "./DevilsBargain-25.png";
import Bargain26 from "./DevilsBargain-26.png";
import Bargain27 from "./DevilsBargain-27.png";
import Bargain28 from "./DevilsBargain-28.png";
import Bargain29 from "./DevilsBargain-29.png";
import Bargain30 from "./DevilsBargain-30.png";
import Bargain31 from "./DevilsBargain-31.png";
import Bargain32 from "./DevilsBargain-32.png";
import Bargain33 from "./DevilsBargain-33.png";
import Bargain34 from "./DevilsBargain-34.png";
import Bargain35 from "./DevilsBargain-35.png";
import Bargain36 from "./DevilsBargain-36.png";
import Bargain37 from "./DevilsBargain-37.png";
import Bargain38 from "./DevilsBargain-38.png";
import Bargain39 from "./DevilsBargain-39.png";
import Bargain40 from "./DevilsBargain-40.png";
import Bargain41 from "./DevilsBargain-41.png";
import Bargain42 from "./DevilsBargain-42.png";
import Bargain43 from "./DevilsBargain-43.png";
import Bargain44 from "./DevilsBargain-44.png";
import Bargain45 from "./DevilsBargain-45.png";
import Bargain46 from "./DevilsBargain-46.png";
import Bargain47 from "./DevilsBargain-47.png";
import Bargain48 from "./DevilsBargain-48.png";
import Bargain49 from "./DevilsBargain-49.png";
import Bargain50 from "./DevilsBargain-50.png";

const bargains = [
  Bargain01,
  Bargain02,
  Bargain03,
  Bargain04,
  Bargain05,
  Bargain06,
  Bargain07,
  Bargain08,
  Bargain09,
  Bargain10,
  Bargain11,
  Bargain12,
  Bargain13,
  Bargain14,
  Bargain15,
  Bargain16,
  Bargain17,
  Bargain18,
  Bargain19,
  Bargain20,
  Bargain21,
  Bargain22,
  Bargain23,
  Bargain24,
  Bargain25,
  Bargain26,
  Bargain27,
  Bargain28,
  Bargain29,
  Bargain30,
  Bargain31,
  Bargain32,
  Bargain33,
  Bargain34,
  Bargain35,
  Bargain36,
  Bargain37,
  Bargain38,
  Bargain39,
  Bargain40,
  Bargain41,
  Bargain42,
  Bargain43,
  Bargain44,
  Bargain45,
  Bargain46,
  Bargain47,
  Bargain48,
  Bargain49,
  Bargain50,
];

export default bargains;
