import {
  Box,
  Button,
  IconButton,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { submit } from "api/gSheets";
import React from "react";
import { RandArray } from "util/randArray";
import adj from "./raw/ratAdj.json";
import job from "./raw/ratJob.json";
import advAdj from "./raw/ratAdvancedAdj.json";
import advJob from "./raw/ratAdvancedJob.json";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";

const RatGenerator: React.FC = function RatGenerator() {
  const rAdj = new RandArray([...adj, ...advAdj]);
  const rJob = new RandArray([...job, ...advJob]);

  const [resAdj, setResAdj] = React.useState(rAdj.random());
  const [resJob, setResJob] = React.useState(rJob.random());
  const onLikeAdj = () => {
    submit({
      category: `rat-good-adj`,
      result: resAdj,
    });
    setResAdj(rAdj.random());
  };
  const onDislikeAdj = () => {
    submit({
      category: `rat-bad-adj`,
      result: resAdj,
    });
    setResAdj(rAdj.random());
  };

  const onLikeJob = () => {
    submit({
      category: `rat-good-job`,
      result: resJob,
    });
    setResJob(rJob.random());
  };

  const onDislikeJob = () => {
    submit({
      category: `rat-bad-job`,
      result: resJob,
    });
    setResJob(rJob.random());
  };

  const reroll = () => {
    setResAdj(rAdj.random());
    setResJob(rJob.random());
  };

  const adjLink = (
    <Stack
      sx={{
        alignItems: "center",
      }}
    >
      <IconButton onClick={onLikeAdj}>
        <CheckIcon color="success" />
      </IconButton>
      <Typography variant="h5">{resAdj}</Typography>
      <IconButton onClick={onDislikeAdj}>
        <CloseIcon color="warning" />
      </IconButton>
    </Stack>
  );
  const jobLink = (
    <Stack
      sx={{
        alignItems: "center",
      }}
    >
      <IconButton onClick={onLikeJob} size="large">
        <CheckIcon color="success" />
      </IconButton>
      <Typography variant="h5">{resJob}</Typography>
      <IconButton onClick={onDislikeJob} size="large">
        <CloseIcon color="warning" />
      </IconButton>
    </Stack>
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        size="large"
        onClick={handleClick}
        sx={{
          position: "absolute",
          top: 64,
          right: 0,
          m: 2,
        }}
      >
        <HelpIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 2, maxWidth: 450 }}>
          An experimental rat generator using the WordNet corpora to extend the
          adjective and job pools. The adjectives are not well-classified, so
          there are many that are not applicable. You can help by voting yea or
          nay on them. Changes from voting are not immediately reflected in the
          generator. It's still manual on my part but I'm planning on automating
          the process soon.
        </Typography>
      </Popover>
      <Stack direction="row" alignItems="center" spacing={1}>
        {adjLink}
        <Typography variant="h5">Rat</Typography>
        {jobLink}
      </Stack>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          m: 2,
        }}
      >
        <Button
          variant="outlined"
          size="large"
          onClick={reroll}
          sx={{
            m: 2,
          }}
        >
          reroll
        </Button>
      </Box>
    </>
  );
};

export default RatGenerator;
